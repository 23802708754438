import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearToken, login } from "../../slices/AuthTokenSlice";
import { AppDispatch } from "../../store";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Link, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { GraphicEq } from "@mui/icons-material";

import './LoginCardComponent.css';
import useLocalStorage from "use-local-storage";

function LoginCardComponent() {

  // set up router navigation
  const navigate = useNavigate();

  // define internal state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  // use dispatch to connect us to the controller
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector((state: any) => state.authToken.token); 
  const loginError = useSelector((state: any) => state.authToken.error);
  const loginLoading = useSelector((state: any) => state.authToken.loading);

  // store the token in local storage
  const [authToken, setAuthToken] = useLocalStorage('authToken', null);

  // input styling
  const inputSx = {
    // Set icon color
    "& .MuiSvgIcon-root": {
      color: "var(--accent-1)"
    },
    // Root class for the input field
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor:  "var(--accent-1) !important",
          borderWidth: "2px"
        }
      },
      "&:hover:not(.Mui-focused)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--accent-1)",
        },
      },
    },
    // Class for the label of the input field
    "& .MuiInputLabel-outlined": {
      "&.Mui-focused": {
        color:  "var(--accent-1)",
      }
    }
  }

  useEffect(() => {
    if(authToken != null) {
      navigate('/');
    }
    if(token) {
      setAuthToken(token);
    }
  }, [token, authToken, setAuthToken, navigate]);

  // call the login reducer with the login request
  const handleLogin = (event: any) => {
    
    // prevent the page from refreshing on form submit
    event.preventDefault();
    
    var formErrors = false;

    setEmailError(!email)
    setPasswordError(!password)

    if (!email || !password) {
      formErrors = true;
    }

    // once we validate the form, we can dispatch the login request
    if (!formErrors) {
      const loginRequest = {
        userId: email,
        password: password
      };

      // make the login request
      dispatch(login(loginRequest))
    }
  }

  // function to route the user to the signup page
  const goToSignup = () => {
    dispatch(clearToken())
    navigate("/signup");
  }

  return (
    <Card className="login-card">
      <CardHeader className="title" title={<Typography variant="h3" component="h2">Welcome</Typography>}></CardHeader>
      <CardContent className="content">
        <form onSubmit={handleLogin}>
          <Box className="login-icon-box">
            <GraphicEq className="login-icon" />
          </Box>
          {loginError? <Typography variant="body1" className="action-error-message">{loginError}</Typography> : ''}
          <TextField 
            onChange={(e) => setEmail(e.target.value)} 
            className="login-input" 
            label="E-mail" 
            variant="outlined"
            error={!!emailError}
            sx={inputSx}
            required>
          </TextField>
          <TextField
            onChange={(e) => setPassword(e.target.value)} 
            className="login-input"
            label="Password" 
            variant="outlined"
            error={!!passwordError}
            type="password"
            autoComplete="on"
            sx={inputSx}
            required>
          </TextField>
          <Button type="submit" className="login-button" variant="contained" size="large" color="primary"> 
            {loginLoading? <CircularProgress className="login-circular-progress"></CircularProgress> : <Typography>Login</Typography>}
          </Button>
          <Typography variant="body1" className="signup-text">New here? <Button onClick={() => goToSignup()} sx={{textTransform: 'none'}} className="signup-link">Sign up</Button></Typography>
        </form>
      </CardContent>
    </Card>
  );
}

export default LoginCardComponent;