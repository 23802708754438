import { configureStore } from '@reduxjs/toolkit';
import authTokenReducer from './slices/AuthTokenSlice';
import userReducer from './slices/UserSlice';
import mySubscriptionsPageReducer from './slices/MySubscriptionsPageSlice';
import languageReducer from './slices/LanguageSlice';
import subscriptionTierReducer from './slices/SubscriptionTierSlice';

const store = configureStore({
  reducer: {
    authToken: authTokenReducer,
    user: userReducer,
    language: languageReducer,
    subscriptionTier: subscriptionTierReducer,
    mySubscriptionsPage: mySubscriptionsPageReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export default store;