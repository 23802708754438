import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from 'use-local-storage';

import './App.css';

import HomePage from './pages/home-page/HomePage';
import LoginPage from './pages/login-page/LoginPage';
import SignupPage from './pages/signup-page/SignupPage';
import HeaderComponent from './global/header-component/HeaderComponent';
import FooterComponent from './global/footer-component/FooterComponent';
import MySubscriptionsPage from './pages/my-subscriptions-page/MySubscriptionsPage';
import PrivacyPage from './pages/privacy-page/PrivacyPage';
import TosPage from './pages/tos-page/TosPage';

import configureAxios from './utils/AxiosInterceptor';
import { AppDispatch } from './store';
import { getUser } from './slices/UserSlice';
import { clearToken } from './slices/AuthTokenSlice';
import WhatsappIntegrationTestPage from './pages/whatsapp-integration-test-page/WhatsappIntegrationTestPage';

// used to set the color scheme for material ui
import { ThemeProvider, createTheme } from '@mui/material/styles';


function App() {
  
  // Set up color themes and preferences
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const isDarkMode = theme === 'dark';

  const muiTheme = createTheme({
    colorSchemes: {
      dark: isDarkMode
    },
    cssVariables: true
  });
  
  const [authToken, setAuthToken] = useLocalStorage('authToken', null);
  
  const dispatch = useDispatch<AppDispatch>();


  // set up axios
  // TODO: actually set up axios
  // extract accessToken from Redux
  const token = useSelector((state: any) => state.authToken.token);
  const userError = useSelector((state: any) => state.user.error);


  useEffect(() => {
    if(authToken) {
      //initialize our Axios and configure it
      // const accessToken = authToken.accessToken;
      // const tokenType = authToken.tokenType;

      // const accessTokenString = `${tokenType} ${accessToken}`;
      dispatch(getUser(authToken));

      // configureAxios({
      //   accessTokenString
      // });
    }
    if(userError === 'Request failed with status code 401' || userError === 'An error occured while authenticating') {
      setAuthToken(null);
      dispatch(clearToken());
    }
  }, [token, authToken, userError, dispatch, setAuthToken]);

  return (

    <ThemeProvider theme={muiTheme}>
      <div className="App" data-theme={theme}>
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/whatsapp" element={<WhatsappIntegrationTestPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms-of-service" element={<TosPage />} />
          <Route path="/subscriptions" element={<MySubscriptionsPage />} />
        </Routes>
        <FooterComponent />
      </div>
    </ThemeProvider>
  );
}

export default App;
