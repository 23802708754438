import { Box, Typography, CircularProgress, Card, Button } from '@mui/material';

import './MySubscriptionsPage.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useLocalStorage from 'use-local-storage';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getMySubscriptions } from '../../slices/MySubscriptionsPageSlice';

// imports for our table (Material React Table v3)
// useMemo specifically is used to memoize the columns array so that it doesn't get recreated on every render
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

// grab the subscription model so the table knows what fields to display
import SubscriptionModel from '../../model/SubscriptionModel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddSubscriptionComponent from './AddSubscriptionComponent';

function MySubscriptionsPage() {

  // call dispatch to get the survey details
  const dispatch = useDispatch<AppDispatch>();

  const [authToken, setAuthToken] = useLocalStorage('authToken', null);
  const navigate = useNavigate();

  const subscriptions: SubscriptionModel [] = useSelector((state: any) => state.mySubscriptionsPage.subscriptions);
  const subscriptionsError = useSelector((state: any) => state.mySubscriptionsPage.error);
  const subscriptionsLoading = useSelector((state: any) => state.mySubscriptionsPage.loading);
  
  // if we use pending then it will draw an empty table while waiting for the data
  const awaitingSubscriptions = subscriptions.length === 0 && subscriptionsError === null;

  // define our table columns
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'whatsappNumber',  // name of model property to display
        header: 'WhatsApp Number',      // column display name
        size: 100                       // column width
      },
      {
        accessorKey: 'language',
        header: 'Native Language',
        size: 75
      },
      {
        accessorKey: 'subscriptionTier',
        header: 'Subscription Tier',
        size: 75
      },
      {
        accessorFn: (row) => new Date(row.createdDtg),
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
        header: 'Created Date',
        size: 50
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        borderRadius: '0',
        border: 'none',
        width: '100%',
        height: '100%'
      }
    },
    columns: columns,
    data: subscriptions //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  // scroll to the top of the page on render
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // redirect to login if there's no auth token
  useEffect(() => {
    if (authToken === null) {
      navigate("/login");
    }
  }, [authToken, navigate])

  // call dispatch to get the list of subscriptions
  useEffect(() => {
    if(subscriptions.length === 0 && !subscriptionsLoading && authToken !== null) {
      dispatch(getMySubscriptions({authToken:authToken}));
    }

  }, [authToken, subscriptions, subscriptionsLoading, dispatch]);

  return (
    <Box className="page-body">
      {/* <AddSubscriptionComponent /> */}
      <Box className="my-subscriptions-box">
        <Card className="my-subscriptions-card">
          <Box className="my-subscriptions-title-box">
            <Typography className="my-subscriptions-title" variant="h5">My WhatsApp Subscriptions</Typography>
            {/* <Button className="add-subscription-button">
              <AddCircleOutlineIcon className="add-subscription-button-icon" />
              <Typography className="add-subscription-button-text">New Subscription</Typography>
            </Button> */}
          </Box>
          <Box className="my-subscriptions-grid-box">
          {awaitingSubscriptions? 
            <CircularProgress className="load-my-subscriptions-circle-progress" size="10rem" />:
            <MaterialReactTable table={table} />
          }
          </Box>
        </Card>

      </Box>
    </Box>
  );
}

export default MySubscriptionsPage;